import { Component, OnInit } from '@angular/core'
import { ConfigService } from 'src/app/services/config/config.service'

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  hasTools: boolean
  constructor(private config: ConfigService) {}

  supportLink: string = this.config.getSettings('spa').customerVoiceLink
  aboutHearHubFeatureLink: string = this.config.getSettings('spa').aboutHearHubFeatureLink

  ngOnInit(): void {
    this.hasTools = localStorage.getItem('hasTools') === 'true'
  }
}
